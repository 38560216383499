import React, { useState, useEffect } from 'react';
import socketIOClient from 'socket.io-client';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import './GoldPrices.css';

const ENDPOINT = "https://socket.haremaltin.com";

const variablesToShow = {
  USDTRY: "Dolar TL",
  ALTIN: "Has Altın",
  ONS: "Ons Altın",
  AYAR22: "22 Ayar Altın",
  AYAR14: "14 Ayar Altın",
  CEYREK_YENI: "Çeyrek Altın - Yeni",
  CEYREK_ESKI: "Çeyrek Altın - Eski",
  YARIM_YENI: "Yarım Altın - Yeni",
  YARIM_ESKI: "Yarım Altın - Eski",
  ATA_YENI: "Ata/Tam Altın - Yeni",
  ATA_ESKI: "Ata/Tam Altın - Eski",
  GUMUSTRY: "Gümüş Gram"
};

const additionalVariables = {
  GRAM_ALTIN_995: "Gram Altın (995)",
  GRAM_ALTIN_925: "Gram Altın (925)"
};

const GoldPrices = () => {
  const [prices, setPrices] = useState({});
  const [updatedKeys, setUpdatedKeys] = useState([]);
  const [lastUpdate, setLastUpdate] = useState(null);

  useEffect(() => {
    const socket = socketIOClient(ENDPOINT, {
      transports: ['websocket'],
      extraHeaders: {
      }
    });

    socket.on('price_changed', (data) => {
      if (data.data) {
        const filteredData = Object.keys(data.data)
          .filter(key => key in variablesToShow)
          .reduce((obj, key) => {
            obj[key] = data.data[key];
            return obj;
          }, {});

        // Gram Altın (995) ve Gram Altın (925) hesaplamaları
        if (filteredData.ALTIN) {
          filteredData.GRAM_ALTIN_995 = {
            alis: (parseFloat(filteredData.ALTIN.alis) * 0.995).toFixed(0),
            satis: (parseFloat(filteredData.ALTIN.satis) * 0.995).toFixed(0),
            tarih: filteredData.ALTIN.tarih
          };

          filteredData.GRAM_ALTIN_925 = {
            alis: (parseFloat(filteredData.ALTIN.alis) * 0.925).toFixed(0),
            satis: (parseFloat(filteredData.ALTIN.satis) * 0.925).toFixed(0),
            tarih: filteredData.ALTIN.tarih
          };
        }

        setPrices(prevPrices => {
          const newPrices = { ...prevPrices, ...filteredData };
          setUpdatedKeys(Object.keys(filteredData));
          setLastUpdate(new Date().toLocaleTimeString('tr-TR'));
          return newPrices;
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (updatedKeys.length > 0) {
      const timer = setTimeout(() => {
        setUpdatedKeys([]);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [updatedKeys]);

  const formatPrice = (key, price) => {
    if (!price) return "-";

    // Add $ sign for ONS
    if (key === 'ONS') {
      return `$${Number(price).toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
    }

    // Format USDTRY with 4 decimal places
    if (key === 'USDTRY') {
      return Number(price).toLocaleString('tr-TR', { minimumFractionDigits: 4, maximumFractionDigits: 4 });
    }

    // Format other values with thousands separator
    return Number(parseInt(price, 10)).toLocaleString('tr-TR');
  };

  const sortedVariablesToShow = [
    'USDTRY',
    'ONS',
    'ALTIN',
    'GRAM_ALTIN_995',
    'GRAM_ALTIN_925',
    ...Object.keys(variablesToShow).filter(key => !['ALTIN', 'USDTRY', 'ONS'].includes(key))
  ];

  return (
    <div className="gold-prices">
      <Card className="price-card">
        <Card.Header>
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="mb-0">Altın & Döviz Fiyatları</h5>
            {lastUpdate && (
              <small className="text-muted">
                Son Güncelleme: {lastUpdate}
              </small>
            )}
          </div>
        </Card.Header>
        <Card.Body className="p-0">
          <Table hover className="table mb-0">
            <thead>
              <tr>
                <th className="text-start">Tür</th>
                <th className="text-end">Alış</th>
                <th className="text-end">Satış</th>
              </tr>
            </thead>
            <tbody>
              {sortedVariablesToShow.map(key => (
                prices[key] && (
                  <tr key={key} className={updatedKeys.includes(key) ? 'highlight' : ''}>
                    <td className="text-start">{variablesToShow[key] || additionalVariables[key]}</td>
                    <td className="text-end buy-price">{formatPrice(key, prices[key].alis)}</td>
                    <td className="text-end sell-price">{formatPrice(key, prices[key].satis)}</td>
                  </tr>
                )
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
};

export default GoldPrices;